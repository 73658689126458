import React from "react"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import PrimaryCta from "@components/PrimaryCta"
import { graphql } from "gatsby"

// Assets
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const IndexPage = ({ location, data }) => {
  const cardStyles = [
    tw`flex flex-col justify-center mb-7 w-full min-h-[170px] py-3.5 px-[5.3vw] text-center rounded-tr-[79px] rounded-b-[13.7px] bg-[rgba(255, 255, 255, 0.7)] [box-shadow: 0 4px 4px rgb(0 0 0 / 25%)] hover:bg-[rgba(255, 255, 255, 0.9)]`,
    tw`lg:(h-full min-h-[300px] mx-auto px-8 w-[calc(100% - 85px)] items-center mb-0 rounded-tr-[158px] rounded-b-[26.3px] z-[-1])`,
  ]

  return (
    <Layout
      location={location}
      headerType="home"
      copyright={
        <>
          &copy; 2023 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <nobr>US-XYW-2200416 Rev1123</nobr>
        </>
      }
    >
      <Seo
        title="XYWAV for Narcolepsy & Idiopathic Hypersomnia | XYWAV"
        description="XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution is approved for people with cataplexy and/or excessive daytime sleepiness (EDS) in narcolepsy or adults with idiopathic hypersomnia (IH). See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      {/* Split hero */}
      <section
        id="split-hero"
        tw="pb-[30px] overflow-hidden lg:(pt-[122px] pb-[50px])"
      >
        <div tw="relative grid [grid-template-columns: repeat(4,1fr)] [column-gap: 30px] w-[calc(100% - 35px)] max-w-[732px] mx-auto lg:(w-full max-w-[1170px] [grid-template-columns: repeat(12,1fr)])">
          <h1 tw="hidden lg:(text-center block col-span-full w-full font-cocon font-bold text-white [font-size: 44px] leading-[100%] pb-20 z-[1] [text-shadow:_0_4px_5px_rgb(0_0_0_/_25%)])">
            Discover XYWAV
          </h1>
          <div tw="flex flex-col text-center col-span-full lg:(flex-row flex-wrap mb-[50px])">
            {/* Left/top section */}
            <div
              css={[
                tw`relative mx-[calc(-50vw - -50%)] px-[calc(50vw - 50%)] pt-[54px] lg:(static w-[50vw] mr-0 pr-0 pt-0)`,
                tw`before:([content:''] absolute w-screen h-[calc(100% + 172px)] left-0 bottom-0 gradientPurpleToRed z-[-1] lg:(gradientPurpleToRedVertical bottom-[-200px] w-[50vw] h-[600px] left-[calc(-50vw - -50%)] top-auto) 3xl:(h-[562px]))`,
                tw`after:(lg:([content:''] absolute h-[calc(100% + 172px)] opacity-50 left-[calc(-50vw - -50%)] bottom-[-50px] w-[50vw] z-[-1] gradientPurpleToRedHorizontal transition-opacity [transition-duration: 0.2s] [transition-timing-function: cubic-bezier(0.645,0.045,0.355,1)]))`,
                tw`hover:(after:(lg:opacity-0))`,
              ]}
              className="group"
            >
              {/* Background image */}
              <div tw="hidden lg:(block absolute left-[calc(-50vw - -50%)] w-[50vw] h-full max-h-[461px] top-[-122px] z-[-2])">
                <GatsbyImage
                  tw="min-h-full w-full"
                  objectPosition="50% 0"
                  image={getImage(data.imageLeft)}
                  alt="Patient speaking to a doctor with an elephant (made of salt) in the room"
                />
              </div>
              <h1 tw="mb-[20px] font-cocon font-bold text-[32px] leading-[100%] text-white lg:(hidden)">
                Discover XYWAV
              </h1>

              {/* Card */}
              <div
                css={[
                  cardStyles,
                  tw`xl:(mr-[85px])`,
                  tw`group-hover:(lg:(bg-[rgba(255, 255, 255, 0.9)]))`,
                ]}
              >
                <h2 tw="font-cocon font-bold text-4xl leading-[28px] mb-2.5 text-merlin lg:(text-[32px] leading-10 mb-[25px])">
                  For Narcolepsy
                </h2>
                <p tw="font-avenir font-medium [font-size: 15px] leading-[19px] mb-[15px] text-merlin lg:(text-xl leading-6 mb-[30px])">
                  XYWAV can help treat cataplexy and/or excessive daytime
                  sleepiness (EDS).
                </p>
                <div tw="mx-auto w-full">
                  <PrimaryCta url="/narcolepsy/" isNarc mdSmall>
                    LEARN MORE
                  </PrimaryCta>
                </div>
              </div>
            </div>

            {/* Right/bottom section */}
            <div
              css={[
                tw`relative mx-[calc(-50vw - -50%)] px-[calc(50vw - 50%)] pt-[30px] lg:(static w-[50vw] ml-0 pl-0 pt-0)`,
                tw`before:([content:''] absolute w-screen h-[calc(100% + 172px)] right-0 top-0 gradientPurpleToOrange z-[-1] lg:(gradientPurpleToOrangeVertical bottom-[-200px] w-[50vw] h-[562px] right-[calc(-50vw - -50%)] top-auto))`,
                tw`after:(lg:([content:''] absolute h-[calc(100% + 172px)] opacity-50 right-[calc(-50vw - -50%)] bottom-[-50px] w-[50vw] z-[-1] gradientPurpleToOrangeHorizontal transition-opacity [transition-duration:0.2s] [transition-timing-function:cubic-bezier(0.645,0.045,0.355,1)]))`,
                tw`hover:(after:(lg:(opacity-0)))`,
              ]}
              className="group"
            >
              {/* Background image */}
              <div tw="hidden lg:(block absolute right-[calc(-50vw - -50%)] w-[50vw] h-full max-h-[461px] top-[-122px] z-[-2])">
                <GatsbyImage
                  tw="min-h-full w-full"
                  objectPosition="center top"
                  image={getImage(data.imageRight)}
                  alt=""
                />
              </div>
              {/* Card */}
              <div
                css={[
                  cardStyles,
                  tw`xl:ml-[90px]`,
                  tw`group-hover:(lg:(bg-[rgba(255, 255, 255, 0.9)]))`,
                ]}
              >
                <h2 tw="font-cocon font-bold text-4xl leading-[28px] mb-2.5 text-merlin lg:(text-[32px] leading-10 mb-[25px])">
                  For Idiopathic Hypersomnia (IH)
                </h2>
                <p tw="font-avenir font-medium [font-size: 15px] leading-[19px] mb-[15px] text-merlin lg:(text-xl leading-6 mb-[30px])">
                  XYWAV is the first and only{" "}
                  <nobr>FDA-approved treatment for adults with&nbsp;IH.</nobr>
                </p>
                <div tw="mx-auto w-full">
                  <PrimaryCta url="/idiopathic-hypersomnia/" isNarc mdSmall>
                    LEARN MORE
                  </PrimaryCta>
                </div>
              </div>
              <p tw="font-normal text-sm text-white lg:(hidden)">
                XYWAV oral solution, 0.5 g/mL total salts (equivalent to 0.413
                g/mL of oxybate) is the only FDA&#8209;approved treatment for
                cataplexy and/or excessive daytime sleepiness in narcolepsy and
                for idiopathic hypersomnia (IH) in adults.
              </p>
            </div>
          </div>

          {/* Bottom copy */}
          <div tw="hidden lg:(block col-span-full text-center)">
            <p tw="lg:(font-avenir font-medium text-xl leading-6 text-white max-w-[1134px] px-[42.5px]) xl:px-0">
              XYWAV oral solution, 0.5 g/mL total salts (equivalent to 0.413
              g/mL of oxybate) is the only
              <br />
              FDA&#8209;approved treatment for cataplexy and/or excessive
              daytime sleepiness in narcolepsy and for idiopathic hypersomnia
              (IH) in adults.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    imageLeft: file(relativePath: { eq: "hero-left.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 720
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    imageRight: file(relativePath: { eq: "hero-right.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 720
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
  }
`
